import React, { useContext } from 'react';
import { makeStyles, Collapse, List, ListItem, Divider } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { CustomThemeContext } from '../themes/CustomThemeProvider';

import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    sidebar:{
        marginTop: 5,
    },
    sidebarItem:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    sidebarItemContent:{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    sidebarItemIcon:{
        minWidth: 56,
        marginRight: 6,
    },
    sidebarItemText:{
        width: '100%',
        fontSize: 17,
        marginTop: 6,
        marginBottom: 6,
      },
    sidebarItemExpandArrow:{
        fontSize: '1.2rem !important',
    },
    sidebarItemExpandArrowExpanded:{
        fontSize: '1.2rem !important',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
  }));

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const { label, items, Icon } = item;
  const { currentMenu, setMenu, currentNode, setNode } = useContext(CustomThemeContext);
  const [collapsed, setCollapsed] = React.useState(currentNode !== item.name);

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick() {
    if (Array.isArray(items)) {
      toggleCollapse();
    }

    if (item.link) {
      setMenu(item.name);
      setNode(item.nodeExpand);
      navigate( item.link );
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon className={classes.sidebarItemExpandArrowExpanded} />
    ) : (
      <ExpandMoreIcon className={classes.sidebarItemExpandArrow} />
    );
  }

  return (
    <>
      <ListItem
        className={classes.sidebarItem}
        onClick={onClick}
        button
        dense
        selected={currentMenu === item.name}
        {...rest}
      >
        <div
          style={{ paddingLeft: depth * depthStep }}
          className={classes.sidebarItemContent}
        >
          {Icon && <Icon className={classes.sidebarItemIcon} />}
          <div className={classes.sidebarItemText}>{label}</div>
        </div>
        {expandIcon}
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider style={{ margin: "6px 0" }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function Sidebar({ items, depthStep, depth, expanded }) {
    const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <List>
        {items.itens.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.name}${index}`}>
            {sidebarItem === "divider" ? (
              <Divider style={{ margin: "6px 0" }} />
            ) : (
              <SidebarItem
                depthStep={depthStep}
                depth={depth}
                expanded={expanded}
                item={sidebarItem}
                />
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
