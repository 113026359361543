import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from '../components/Loadable';
import MinimalLayout from '../components/MinimalLayout';

// login option 3 routing
const Home = Loadable(lazy(() => import('../pages/client/home')));

const Partners = Loadable(lazy(() => import('../pages/client/partners')));
const PartnersDetail = Loadable(lazy(() => import('../pages/client/partners/partners.detail')));

const Login = Loadable(lazy(() => import('../pages/admin/shared/login/index')));
const ForgotPassword = Loadable(lazy(() => import('../pages/admin/shared/login/forgotpassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/admin/shared/login/resetpassword')));

const NotFound = Loadable(lazy(() => import('../pages/client/notfound')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/partners',
            element: <Partners />
        },
        {
            path: '/partners/:idAcademia',
            element: <PartnersDetail />
        },
        {
            path: '/login/:source',
            element: <Login />
        },
        {
            path: '/forgotpassword/:source',
            element: <ForgotPassword />
        },
        {
            path: '/resetpassword/:source/:token',
            element: <ResetPassword />
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
        {
            path: '/404',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
