import React, { Component } from 'react';
import Routes from './routes';
import NavigationScroll from './components/NavigationScroll';
import { BrowserRouter } from 'react-router-dom';


class App extends Component {
    render() {
      return (
        <BrowserRouter>
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </BrowserRouter>
      );
    }
  }

export default App;
