import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import FitnessCenter from '@material-ui/icons/FitnessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { getIdUsuario } from '../../services/auth';

const userId = getIdUsuario();

export const mainListItems_g = [
    { name: "dashboard", label: "Dashboard", Icon: DashboardIcon, link: '/gym/dashboard', nodeExpand: '' },
    {
        name: "cadastros",
        label: "Cadastros",
        Icon: AssignmentIcon,
        items: [
            { name: "usuarios", label: "Usuários", Icon: PeopleIcon, link: '/gym/usuarios/'+userId, nodeExpand: 'cadastros' },
            { name: "academias", label: "Academias", Icon: FitnessCenter, link: '/gym/academia/'+userId, nodeExpand: 'cadastros' }
        ]
    },
    'divider',
    { name: "account", label: "Perfil do Usuário", Icon: AccountCircle, link: '/account2/' +userId, nodeExpand: '' },    
];

