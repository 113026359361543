import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomThemeProvider from './themes/CustomThemeProvider';
import Provider from 'react-redux/es/components/Provider';
import store from './store';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

ReactDOM.render(
    <CustomThemeProvider>
        <ErrorBoundary fallback='Oops, ocorreu um erro! Tente novamente mais tarde!'>
            <Provider store={store}>
                <CssBaseline />
                <App />
            </Provider>
        </ErrorBoundary>
    </CustomThemeProvider>
    ,document.getElementById('root')
);
