import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

import { logout, getToken } from '../services/auth';
import api from '../services/api';

//import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({ children, source }) => {
    const [ redirect, setRedirect ] = useState(false);

    useEffect(() => {
        async function verify(){

            const Token = await getToken();

            if(getToken() === undefined){
                logout();
                setRedirect(true);
                return;
            }

            const isLogged = await api.get('/api/usuarios/checktoken', {params:{token:Token}});

            if(isLogged.data.status===200){
              //api.defaults.headers.common['Authorization'] = `Bearer ${Token}`;
                setRedirect(false);
                return;
            }
            
            logout();
            setRedirect(true);
        }
        verify();
    },[])


    if (redirect) {
        // user is not authenticated
        return <Navigate to={`/login/${source}`} replace />;
      }

    return children;
};