import axios from 'axios';
import { config } from '../config';
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: config.APIbaseURL
})

if(Cookies.get('@opengym/user1'))
    api.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('@opengym/user1')}`;

// api.defaults.baseURL
export default api;