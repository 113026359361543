import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// DOC: https://material-ui.com/pt/customization/default-theme/

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#E65025',
      light: 'rgb(81, 91, 95)',
      dark: 'rgb(200, 147, 89)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: 'rgb(81, 91, 95)',
      light: 'rgb(255, 197, 112)',
      dark: 'rgb(200, 147, 89)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "0.9em",
          color: '#303030',
          backgroundColor: '#ffffff'
        }
      },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #303030 inset',
          WebkitTextFillColor: '#fff',
        },
      },
    },
    MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #424242 inset',
            WebkitTextFillColor: '#fff',
          },
        },
      },
   }
})

export default theme