import React from 'react';
import {Snackbar, IconButton, SnackbarContent} from '@material-ui/core';
import {green, amber, blue} from '@material-ui/core/colors';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import * as Actions from '../store/actions';
import {makeStyles} from '@material-ui/styles';
import { CheckCircle, Warning, Error, Info, Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root   : {},
    success: {
        backgroundColor: green[600],
        color          : '#FFFFFF'
    },
    error  : {
        backgroundColor: theme.palette.error.dark,
        color          : theme.palette.getContrastText(theme.palette.error.dark)
    },
    info   : {
        backgroundColor: blue[600],
        color          : '#FFFFFF'
    },
    warning: {
        backgroundColor: amber[600],
        color          : '#FFFFFF'
    },
    Icon:{
        paddingRight: 20,
        paddingTop: 5
    },

    Message:{
        fontSize: 17,
        alignItems: 'center',
        justifyContent: 'center',
        display:'flex',
    }
}));

export default function FuseMessage(props)
{
    const dispatch = useDispatch();
    const state = useSelector(({fuse}) => fuse.message.state);
    const options = useSelector(({fuse}) => fuse.message.options);

    const classes = useStyles();

    return (
        <Snackbar
            {...options}
            open={state}
            onClose={() => dispatch(Actions.hideMessage())}
            classes={{
                root: classes.root
            }}
            ContentProps={{
                variant        : 'body2',
                headlineMapping: {
                    body1: 'div',
                    body2: 'div'
                }
            }}
        >
            <SnackbarContent
                className={clsx(classes[options.variant])}
                message={
                    <div className={classes.Message}>
                        { options.variant === 'success' && (
                            <div className={classes.Icon}><CheckCircle/></div>
                        )}
                        { options.variant === 'warning' && (
                            <div className={classes.Icon}><Warning/></div>
                        )}
                        { options.variant === 'error' && (
                            <div className={classes.Icon}><Error/></div>
                        )}
                        { options.variant === 'info' && (
                            <div className={classes.Icon}><Info/></div>
                        )}
                        {options.message}
                    </div>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => dispatch(Actions.hideMessage())}
                    >
                        <Close/>
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}