  
import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#E65025',
    },
    secondary: {
      main: '#cc4444',
      light: 'rgb(255, 197, 112)',
    },
    error: {
      main: red.A400,
    },
    background: {
        paper: '#EFEFEF',
        default: '#DCC7AA',
    },
  },
  overrides: {
    MuiTooltip: {
        tooltip: {
          fontSize: "0.9em",
          color: '#E9E7DA',
          backgroundColor: '#303030'
        }
      },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #fff inset',
          WebkitTextFillColor: 'black',
        },
      },
    },
    MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
            WebkitTextFillColor: 'black',
          },
        },
      },
  }



})

export default theme