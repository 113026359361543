import React, { useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import getTheme from './base'

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext(
  {
    currentTheme: 'normal',
    setTheme: null,
    currentMenu: '',
    setMenu: null,
    currentNode: '',
    setNode: null,
    currentTitle: '',
    setTitle: null,
  },
)

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props

  const currentTheme = localStorage.getItem('@opengym/appTheme') || 'normal'
  const currentMenu = sessionStorage.getItem('@opengym/appMenu') || ''
  const currentNode = sessionStorage.getItem('@opengym/appNode') || ''
  const currentTitle = ''


  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(currentTheme)
  const [themeMenu, _setThemeMenu] = useState(currentMenu)
  const [themeNode, _setThemeNode] = useState(currentNode)
  const [themeTitle, _setThemeTitle] = useState(currentTitle)

  // Retrieve the theme object by theme name
  const theme = getTheme(themeName)

  const setThemeName = (name) => {
    localStorage.setItem('@opengym/appTheme', name)
    _setThemeName(name)
  }

  const setThemeMenu = (name) => {
    sessionStorage.setItem('@opengym/appMenu', name)
    _setThemeMenu(name)
  }

  const setThemeNode = (name) => {
    sessionStorage.setItem('@opengym/appNode', name)
    _setThemeNode(name)
  }

  const setThemeTitle = (title) => {
    _setThemeTitle(title)
  }

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
    currentMenu: themeMenu,
    setMenu: setThemeMenu,
    currentNode: themeNode,
    setNode: setThemeNode,
    currentTitle: themeTitle,
    setTitle: setThemeTitle,
  }

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider